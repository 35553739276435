<template>
    <main>
        <div class="mt-4" v-for="(formula,index) in formulas" :key="index" >
            <div class="card-header bg-ligth">
                <div class="row">
                    <div class="col-6 ">
                        Formula No. {{index+1}} 
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        {{formula.created_at | formatDateTime}} 
                    </div>
                </div>
            </div>
            <div v-for="item in formula.items" :key="item.id">
                <div class="card-body" >
                    <h6>Codigo: <span class="text-danger">{{item.servicio.codigo}}</span></h6>
                    <h6>Medicamento: <span class="text-primary">{{item.servicio.descripcion}}</span></h6>
                    <h6>Posologia: {{ item.dosis }} cada {{ item.frecuencia }}
                        {{ item.unidad_frecuencia }} Por {{ item.duracion }} días</h6>
                    <h6>Total: <span class="badge badge-secondary badge-marketing">{{item.total}}</span></h6>     
                </div>
            </div>
            
        </div>
    </main>
</template>
<script>

import formulaMedicaService from '../../../../services/formulaMedicaService';
import Toast from '../../../../components/common/utilities/toast';

export default {
    props: ['idHistoria','finalizada'],
    data(){
        return {
            formulas:{}
        }
    },
    methods: {
        async cargarFormulas(){
            const response = await formulaMedicaService.showFormulaByHc(this.idHistoria);
            this.formulas= response.data;
        },
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            await this.cargarFormulas();
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    }
}
</script>